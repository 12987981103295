import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class WatcherService {
    private headerViewSource = new BehaviorSubject<any>('');
    onHeaderViewUpdate = this.headerViewSource.asObservable();

    private announcementBannerStatusSource = new BehaviorSubject<Boolean>(true);
    onAnnouncementBannerStatusUpdate = this.announcementBannerStatusSource.asObservable();
    
    private announcementBannerHeightSource = new BehaviorSubject<number>(0);
    onAnnouncementBannerHeightUpdate = this.announcementBannerHeightSource.asObservable();
    
    private shoutbarHeightSource = new BehaviorSubject<number>(0);
    onShoutbarHeightUpdate = this.shoutbarHeightSource.asObservable();

    constructor() { }
    whichHeader(view: String) {
        this.headerViewSource.next(view);
    }

    activeAnnouncementBanner(status: Boolean){

        let today = new Date().getTime();
        let countdownEndDate = 1669438800 * 1000;
        if(countdownEndDate <= today){
            this.announcementBannerStatusSource.next(false);
        } else { 
            this.announcementBannerStatusSource.next(status);
        }
        
    }

    announcementBannerHeightUpdate(height: number){
        this.announcementBannerHeightSource.next(height);
    }

    shoutbarHeightUpdate(height: number){
        this.shoutbarHeightSource.next(height)
    }
}